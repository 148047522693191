
html {
  // font-size: 1rem;
  box-sizing: border-box;
  font-size: 16px;
  @include breakpoint(md) {
    font-size: 18px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base-mobile;
  font-weight: $font-weight-normal;
  line-height: $line-height-base-mobile;
  @include font-smoothing(on);
  @include breakpoint(md) {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

//
// Headings
//

h1, .is-h1, h2, .is-h2, h3, .is-h3, h4, .is-h4, h5, .is-h5, h6, .is-h6 {
  display: block;
  margin: 0 0 $line-space-mobile;
  padding: 0;
  color: $sn-white;
  @include breakpoint(md) {
    margin-bottom: $line-space;
  }  
}

h1, .is-h1 {
  font-size: rem(36,$font-size-base-number-mobile);
  font-weight: $font-weight-normal;
  line-height: 1;
  letter-spacing: -1px;

  @include breakpoint(md) {
    font-size: rem(64,$font-size-base-number);
    font-size: rem(52,$font-size-base-number);
    line-height: 1.15384615385; // 60
  }
}

h2, .is-h2 {
  font-size: rem(28,$font-size-base-number-mobile);
  font-weight: $font-weight-normal;
  line-height: 1.14285714286; // 32
  letter-spacing: -0.03em;

  @include breakpoint(md) {
    font-size: rem(36,$font-size-base-number);
    line-height: 1.11111111111; // 40
  }
}

h3, .is-h3 {
  font-size: rem(22,$font-size-base-number-mobile);
  font-weight: $font-weight-normal;
  line-height: 1.09090909091; // 24
  letter-spacing: -0.015em;
  
  p + & {
    margin-top: $line-space-2x-mobile;
  }

  @include breakpoint(md) {
    font-size: rem(26,$font-size-base-number);
    line-height: 1.23076923077; // 32
    
    p + & {
      margin-top: $line-space-2x;
    }
  }
}

h4, .is-h4 {
  margin-bottom: 0;
  font-size: $font-size-base-mobile;
  font-weight: $font-weight-normal;
  line-height: $line-height-base-mobile;
  letter-spacing: normal;

  @include breakpoint(md) {
    font-size: $font-size-base;
    line-height: $line-height-base-mobile;
  }
}

h5, .is-h5,
h6, .is-h6,
.is-eyebrow {
  margin-bottom: 0;
  font-size: rem(12,$font-size-base-number-mobile);
  font-weight: $font-weight-normal;
  line-height: 1.333333333; // 16
  letter-spacing: 1px;
  text-transform: uppercase;
  @include breakpoint(md) {
    font-size: rem(15,$font-size-base-number);
    line-height: 1.33333333333; // 20
  }
}

h6, .is-h6 {
  color: $sn-gray-light;
}

.is-eyebrow {
  display: block;
  color: $sn-gray-light;
}

//
// Paragraphs and lists
//

p,
ol,
ul,
dl {
  margin: 0 0 $line-space;
  font-size: $font-size-base-mobile;
  font-weight: $font-weight-normal;
  line-height: $line-height-base-mobile;
  @include breakpoint(md) {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

p.is-small, .is-small, .is-small p {
  font-size: rem(12,$font-size-base-number-mobile);
  line-height: 1.333333333; // 16
  @include breakpoint(md) {
    font-size: rem(15,$font-size-base-number);
    line-height: 1.333333333; // 20
  }
}

ul, ol {
  margin-left: 20px;
  padding: 0;
}

li {
  padding: 0;
  margin: 0;
  line-height: $line-height-base-mobile;
  @include breakpoint(md) {
    line-height: $line-height-base;
  }
}

li + li,
li > ul {
  margin-top: $line-space-half-mobile;
  @include breakpoint(md) {
    margin-top: $line-space-half;
  }
}

ul.is-plain,
ul.is-unstyled {
  margin-left: 0;
  padding-left: 0;
  text-indent: none;
  list-style: none;
}

ul.is-inline {
  > li {
    display: inline-block;
    margin: 0;
  }
}

ul.is-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > li {
    margin: 0;
  }
}

//
// Links
//

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: $sn-gray;
  text-decoration-thickness: 1px;
  text-underline-offset: .25em;
  transition: $transition-link;

  &:hover,
  &:active,
  &:focus {
    text-decoration-color: transparent;
  }
}

a.is-incognito {
  text-decoration-color: transparent;
  &:hover,
  &:active,
  &:focus {
    text-decoration-color: $sn-gray;
  }
}

a.is-pretty {
  
}

//
// Inline elements
//

strong {
  font-weight: $font-weight-medium;
}

em {
  font-style: italic;
}

.is-light {
  font-weight: $font-weight-light;
}

//
// Block elements
//

hr {
  border: 0;
  border-bottom: 1px solid $sn-gray-dark;
}

nav ul,
nav ol {
  @include list-reset();
}

img, svg {
  max-width: 100%;
  height: auto;
}

svg.icon path {
  fill: currentColor;
}

blockquote, figure {
  margin: 0;
}

figure {
  img {
    display: block;
  }
}

figcaption, .figcaption {
  display: block;
  margin: $grid-gutter-width 0 0;
  font-style: normal;
  > *:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  border-left: 1px solid $sn-gray-dark;
  padding-left: 20px;
  font-style: normal;
}

address {
  font-style: normal;

  p + p {
    margin-top: $line-space-half-mobile;
  }
  @include breakpoint(md) {
    p + p {
      margin-top: $line-space-half;
    }
  }
}

//
// Text-width limits
//

h1, .is-h1,
h2, .is-h2,
h3, .is-h3,
h4, .is-h4,
h5, .is-h5,
h6, .is-h6,
p, li, dt, dd {
  
  @include breakpoint(lg) {
    max-width: $sn-space-8-16-w;
  }
  
  @include breakpoint(xxl) {
    max-width: $sn-space-3-8-w;
  }
}