//
// Lists
//

// Services list shares type stats of h3

.list--services {
	@include list-reset;
	
	li {
		border-top: 1px dotted $sn-gray;
		padding-top: $grid-gutter-height;
		padding-bottom: $grid-gutter-height * 2;
		font-size: rem(22,$font-size-base-number-mobile);
		font-weight: $font-weight-normal;
		line-height: 1.09090909091; // 24
		letter-spacing: -0.015em;
	}
	
	@include breakpoint(md) {
		li {
			font-size: rem(26,$font-size-base-number);
			line-height: 1.23076923077; // 32
		}
	}
}

//
// Two columns

.list--2col {
	column-count: 2;
	column-gap: $grid-gutter-width;
	li {
		break-inside: avoid;
	}
}
.list--2col-mobile {
	@include breakpoint-down(md) {
		column-count: 2;
		column-gap: $grid-gutter-width;
		li {
			break-inside: avoid;
		}
	}
}
.list--2col-md {
	@include breakpoint-only(md, lg) {
		column-count: 2;
		column-gap: $grid-gutter-width;
		li {
			break-inside: avoid;
		}
	}
}
.list--2col-lg {
	@include breakpoint-only(lg, xl) {
		column-count: 2;
		column-gap: $grid-gutter-width;
		li {
			break-inside: avoid;
		}
	}
}
.list--2col-xl {
	@include breakpoint(xl) {
		column-count: 2;
		column-gap: $grid-gutter-width;
		li {
			break-inside: avoid;
		}
	}
}