//
// Enlargement
//

$clearance: $grid-gutter-width;
$clearance-button: $clearance * 0.5;
$button-size: 50px;

//
// Things that can be enlarged

.fig[data-action="enlarge"] {
	cursor: zoom-in;
}

//
// Enlarge modal

.enlarge-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: $sn-black-overlay;
	background-image: url(../../images/icons/sn-icon-time-gray.svg);
	background-size: #{$button-size * 0.5} #{$button-size * 0.5};
	background-repeat: no-repeat;
	background-position: center center;
	z-index: $z-index-modal;

	.fig {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: $clearance;
		cursor: zoom-out;
		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: contain;
		}
	}
}

//
// Enlarged nav

.enlarge__close,
.enlarge__prev,
.enlarge__next {
	@include hide-text;
	position: fixed;
	border-radius: $button-size * 0.5;
	height: $button-size;
	width: $button-size;
	text-align: center;
	background-color: $sn-black-overlay;
	background-repeat: no-repeat;
	background-size: $button-size $button-size;
	color: white;
	z-index: $z-index-modal + 2;
	cursor: pointer;
	transition: all .3s ease;
	&:hover,
	&:active {
		opacity: 0.5;
	}
}

// Close button

.enlarge__close {
	top: $clearance-button;
	right: $clearance-button;
	background-image: url(../../images/icons/sn-icon-close-gray.svg);
}

// Prev button

.enlarge__prev {
	top: 50vh;
	left: $clearance-button;
	background-image: url(../../images/icons/sn-icon-carat-gray.svg);
	transform: translate(0,-50%) rotate(180deg);
}

// Next button

.enlarge__next {
	top: 50vh;
	right: $clearance-button;
	background-image: url(../../images/icons/sn-icon-carat-gray.svg);
	transform: translate(0,-50%);
}

//
// Enlarge counter

.enlarge-modal__counter {
	position: absolute;
	top: 0;
	left: 0;
	padding: $clearance ($clearance * .5) ($clearance * .5) $clearance;
	font-weight: $font-weight-normal;
	line-height: 1;
	background: $sn-black;
	color: $sn-gray-light;
	
	@include breakpoint(md) {
		top: auto;
		bottom: 0;
		padding: ($clearance * .5) ($clearance * .5) $clearance $clearance;
	}
}

//
// States

// Not enlarged

.enlarge-modal {
	display: none;
}

.enlarge-nav {
	display: none;
}

// Something is enlarged

.is-enlarge-modal-active {
	body {
		height: 100vh;
		overflow-y: hidden;
	}
	
	.enlarge-modal {
		display: block;
	}
	
	.enlarge-nav {
		display: block;	
	}
}

//
// Any figure that can be enlarged
//

[data-action="enlarge"] {
	scroll-margin-top: calc($grid-gutter-width - 1px);
}