//
// Page
//

.page {
  position: relative;
  padding-top: $sn-space-1-8-h;
  z-index: $z-index-figures;

  .page__header {
    min-height: $sn-space-4-16-h;
    padding-bottom: $sn-space-1-16-h;
    margin-right: $sn-space-1-16-w;
    
    > *:last-child {
	    margin-bottom: 0;
    }
    
    h1 {
      margin-bottom: $line-space-half-mobile;
    }
    
    .is-eyebrow {
      display: none;
      margin-top: -16px;
    }
  }

  @include breakpoint(md) {
    
    .page__header {
      h1 {
        margin-bottom: $line-space-half;
      }
      .is-eyebrow {
        margin-top: -19px;
      }
    }
    
  }
  
  @include breakpoint(lg) {
    
    .page__header {
      margin-left: $sn-space-3-16-w;
      margin-right: $sn-space-3-16-w;
    }
    
  }
  
  @include breakpoint(xl) {
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    
    .page__header {
      position: -webkit-sticky;
      position: sticky;
      top: $viewport-padding-vert;
      width: $sn-space-4-16-w + $sn-space-1-32-w;
      max-width: $sn-space-4-16-w + $sn-space-1-32-w;
      flex-basis: $sn-space-4-16-w + $sn-space-1-32-w;
      margin-left: 0;
      margin-right: 0;
    }
    
    .page__content {
      width: $sn-space-9-16-w;
      max-width: $sn-space-9-16-w;
      flex-basis: $sn-space-9-16-w;
    }
     
  }
}


