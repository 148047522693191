//
// Details list
//

/*
table.details
	tbody
		tr
			th
			td
*/

.details {
	display: block;
	width: 100%;
	
	tbody,
	tr,
	th,
	td {
		display: block;
		margin: 0;
		padding: 0;
		text-align: left;
	}
	
	tr {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		border-bottom: 1px dotted $sn-gray;
		width: 100%;
		&:last-child {
			border-bottom-width: 0;
		}
	}
	
	th,
	td {
		padding-top: $grid-gutter-height;
		padding-bottom: $grid-gutter-height;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	
	th {
		@extend .is-h6;
		padding-right: max($grid-gutter-width, $grid-gutter-min);
		width: $sn-space-5-16-w;
		line-height: $line-space-mobile;
	}
	
	td {
		flex: 1;
		transform: translate(0, -1.5px);
	}

	@include breakpoint(sm) {
		th {
			width: $sn-space-3-16-w;
		}
	}
	
	@include breakpoint(md) {
		th {
			line-height: $line-space;
		}
	}
	
	@include breakpoint(lg) {
		th {
			width: $sn-space-1-8-w;
		}
	}
}