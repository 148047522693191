//
// Grid
//

//
// Using the grid module

.grid-layout {
	display: grid;
	column-gap: $grid-gutter-width;
	row-gap: $line-space-mobile;
	grid-template-columns: repeat(auto-fill, minmax($grid-column-min-width, 1fr));
	
	> * {
		> *:last-child {
			margin-bottom: 0;
		}
	}
	
	> * + * {
		margin-top: 0;
	}
	
	@include breakpoint(md) {
		row-gap: $line-space;
	}
	
	//
	// Variations
	
	&.grid-layout--row-1-8 {
		grid-auto-rows: minmax(calc($sn-space-1-8-h - $grid-gutter-height), 1fr);
		> * {
			min-height: auto;
		}
	}
	
	&.grid-layout--tight {
		row-gap: $grid-gutter-width;
	}
	
	&.grid-layout--project {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		row-gap: $grid-gutter-width;
		
		.fig {
			grid-column-start: span 2;
		}
		.fig--full {
			grid-column-start: 1;
			grid-column-end: -1;
		}
		.fig--quarter {
			grid-column-start: span 1;
		}
	}
}

.grid {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	margin: 0;
	margin-top: -$grid-gutter-width;
	padding: 0;
	> * {
		display: block;
		margin: 0;
		margin-top: $grid-gutter-width;
		margin-right: $grid-gutter-width;
		padding: 0;
	}
}

//
// 2-up variations

.grid.grid--2up {
	> * {
		width: col-span(1,2,$grid-gutter-width);
		max-width: col-span(1,2,$grid-gutter-width);
		flex-basis: col-span(1,2,$grid-gutter-width);
	}
	> *:nth-child(2n) {
		margin-right: 0;
	}
}
.grid.grid--2up-mobile {
	@include breakpoint-down(md) {
		> * {
			width: col-span(1,2,$grid-gutter-width);
			max-width: col-span(1,2,$grid-gutter-width);
			flex-basis: col-span(1,2,$grid-gutter-width);
		}
		> *:nth-child(2n) {
			margin-right: 0;
		}
	}
}
.grid.grid--2up-md {
	@include breakpoint-only(md, lg) {
		> * {
			width: col-span(1,2,$grid-gutter-width);
			max-width: col-span(1,2,$grid-gutter-width);
			flex-basis: col-span(1,2,$grid-gutter-width);
		}
		> *:nth-child(2n) {
			margin-right: 0;
		}
	}
}
.grid.grid--2up-lg {
	@include breakpoint-only(lg, xl) {
		> * {
			width: col-span(1,2,$grid-gutter-width);
			max-width: col-span(1,2,$grid-gutter-width);
			flex-basis: col-span(1,2,$grid-gutter-width);
		}
		> *:nth-child(2n) {
			margin-right: 0;
		}
	}
}
.grid.grid--2up-xl {
	@include breakpoint(xl) {
		> * {
			width: col-span(1,2,$grid-gutter-width);
			max-width: col-span(1,2,$grid-gutter-width);
			flex-basis: col-span(1,2,$grid-gutter-width);
		}
		> *:nth-child(2n) {
			margin-right: 0;
		}
	}
}

//
// 3-up variations

.grid.grid--3up {
	> * {
		width: col-span(1,3,$grid-gutter-width);
		max-width: col-span(1,3,$grid-gutter-width);
		flex-basis: col-span(1,3,$grid-gutter-width);
	}
	> *:nth-child(3n) {
		margin-right: 0;
	}
}
.grid.grid--3up-mobile {
	@include breakpoint-down(md) {
		> * {
			width: col-span(1,3,$grid-gutter-width);
			max-width: col-span(1,3,$grid-gutter-width);
			flex-basis: col-span(1,3,$grid-gutter-width);
		}
		> *:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.grid.grid--3up-md {
	@include breakpoint-only(md, lg) {
		> * {
			width: col-span(1,3,$grid-gutter-width);
			max-width: col-span(1,3,$grid-gutter-width);
			flex-basis: col-span(1,3,$grid-gutter-width);
		}
		> *:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.grid.grid--3up-lg {
	@include breakpoint-only(lg, xl) {
		> * {
			width: col-span(1,3,$grid-gutter-width);
			max-width: col-span(1,3,$grid-gutter-width);
			flex-basis: col-span(1,3,$grid-gutter-width);
		}
		> *:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.grid.grid--3up-xl {
	@include breakpoint(xl) {
		> * {
			width: col-span(1,3,$grid-gutter-width);
			max-width: col-span(1,3,$grid-gutter-width);
			flex-basis: col-span(1,3,$grid-gutter-width);
		}
		> *:nth-child(3n) {
			margin-right: 0;
		}
	}
}

//
// 4-up variations

.grid.grid--4up {
	> * {
		width: col-span(1,4,$grid-gutter-width);
		max-width: col-span(1,4,$grid-gutter-width);
		flex-basis: col-span(1,4,$grid-gutter-width);
	}
	> *:nth-child(4n) {
		margin-right: 0;
	}
}
.grid.grid--4up-mobile {
	@include breakpoint-down(md) {
		> * {
			width: col-span(1,4,$grid-gutter-width);
			max-width: col-span(1,4,$grid-gutter-width);
			flex-basis: col-span(1,4,$grid-gutter-width);
		}
		> *:nth-child(4n) {
			margin-right: 0;
		}
	}
}
.grid.grid--4up-md {
	@include breakpoint-only(md, lg) {
		> * {
			width: col-span(1,4,$grid-gutter-width);
			max-width: col-span(1,4,$grid-gutter-width);
			flex-basis: col-span(1,4,$grid-gutter-width);
		}
		> *:nth-child(4n) {
			margin-right: 0;
		}
	}
}
.grid.grid--4up-lg {
	@include breakpoint-only(lg, xl) {
		> * {
			width: col-span(1,4,$grid-gutter-width);
			max-width: col-span(1,4,$grid-gutter-width);
			flex-basis: col-span(1,4,$grid-gutter-width);
		}
		> *:nth-child(4n) {
			margin-right: 0;
		}
	}
}
.grid.grid--4up-xl {
	@include breakpoint(xl) {
		> * {
			width: col-span(1,4,$grid-gutter-width);
			max-width: col-span(1,4,$grid-gutter-width);
			flex-basis: col-span(1,4,$grid-gutter-width);
		}
		> *:nth-child(4n) {
			margin-right: 0;
		}
	}
}

