//
// Lazyload
//

/*
See: https://github.com/aFarkas/lazysizes/blob/gh-pages/README.md

CSS API info

lazysizes adds the class lazyloading while the images are loading and the class lazyloaded as soon as the image is loaded. This can be used to add unveil effects:

// fade image in after load
.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

// fade image in while loading and show a spinner as background image (good for progressive images)

.lazyload {
	opacity: 0;
}

.lazyloading {
	opacity: 1;
	transition: opacity 300ms;
	background: #f7f7f7 url(loader.gif) no-repeat center;
}
*/

.fig img {
	&.lazyload {
		opacity: 0;
	}
	&.lazyloading {
		opacity: 1;
		transition: $transition-base;
	}
	&.lazyloaded {
		opacity: 1;
		transition: $transition-base;
	}
}
