//
// Figures
//

figure {
	
	& + figure {
		margin-top: $line-space-mobile;
	}
	
	figcaption {
		margin-top: $grid-gutter-height-half;
	}
	
	@include breakpoint(md) {
		& + figure {
			margin-top: $line-space;
		}
	}
}

a {
	figure {
		img {
			opacity: 1;
			transition: $transition-base;
		}
		figcaption {
			transition: $transition-link;
			text-decoration-color: transparent;
		}
	}
	
	&:hover,
	&:active {
		figure {
			img {
				filter: grayscale(1) brightness(0.6);
			}
			figcaption {
				text-decoration-color: $sn-gray;
			}
		}
	}
}

//
// Variations

.fig--cover,
.fig--16-10,
.fig--16-13,
.fig--8-5,
.fig--1-1,
.fig--1-2,
.fig--2-1,
.fig--2-3,
.fig--4-3,
.fig--4-5,
.fig--4-7 {
	img {
		object-fit: contain;
		width: 100%;
	}
}

.fig--cover {
	img {
		aspect-ratio: 4 / 5;
	}
}

.fig--free {
	min-height: 325px;
	img {
		object-fit: cover;
		width: 100%;
	}
}

.fig--16-10 {
	img {
		aspect-ratio: 16 / 10;
	}
}

.fig--16-13 {
	img {
		aspect-ratio: 16 / 13;
	}
}

.fig--8-5 {
	img {
		aspect-ratio: 8 / 5;
	}
}

.fig--1-1 {
	img {
		aspect-ratio: 1;
	}
}

.fig--1-2 {
	img {
		aspect-ratio: 1 / 2;
	}
}

.fig--2-1 {
	img {
		aspect-ratio: 2 / 1;
	}
}

.fig--2-3 {
	img {
		aspect-ratio: 2 / 3;
	}
}

.fig--4-3 {
	img {
		aspect-ratio: 4 / 3;
	}
}

.fig--4-5 {
	img {
		aspect-ratio: 4 / 5;
	}
}

.fig--4-7 {
	img {
		aspect-ratio: 4 / 7;
	}
}