//
// Base body styles
//

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  background-color: $sn-black;
  color: $sn-gray-light;  
}

.site-header,
.site-footer {
  flex-shrink: 0;
}
