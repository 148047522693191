//
// Light mode
//

.is-light-mode {
	

//
// Background regions

body,
.wrapper {
	background-color: $sn-white;
	color: $sn-gray-dark;
}	

//
// Details table

.details {
	tr {
		border-bottom-color: $sn-gray-medium;
	}
}	

//
// Dividers

.divider {
	border-top-color: $sn-gray-medium;
}

.divider--stealth {
	border-top-color: transparent;
}

//
// Enlarge

// Enlarge modal
.enlarge-modal {
	background-color: $sn-white-overlay;
}

// Enlarge nav
.enlarge__close,
.enlarge__prev,
.enlarge__next {
	background-color: $sn-white-overlay;
	color: $sn-black;
}

// Enlarge counter
.enlarge-modal__counter {
	background: $sn-white;
	color: $sn-gray-dark;	
}

//
// Jump menu

$indicator-border-color--light-mode: $sn-gray-medium;
$indicator-dot-color--light-mode: $sn-black;

// Base styles

.jump-menu {	
	ul {
		// The vertical line connecting the nodes
		li:not(:first-child)::after {
			background-color: $indicator-border-color--light-mode;
		}
		// The links
		a {
			// The empty node
			&::before {
				border-color: $indicator-border-color--light-mode;
				background-color: $sn-white;
			}
			// The node highlight
			&::after {
				background-color: $sn-black;
			}
		}
		// The label
		.jump__label {
			color: $sn-gray-dark;
		}
	}
}

// Light mode mobile vs. desktop differences

.jump-menu {
	// Mobile
	@include breakpoint-down(md) {
		background-color: $sn-white-overlay;		
	}
	// Non-mobile
	@include breakpoint(md) {
		ul {
			// The labels
			.jump__label {
				background-color: $sn-white;
			}
		}
	}
}

// States for the nodes

// Default
.jump-menu ul a::before {
	border-color: $indicator-border-color--light-mode;
}

// Hover and active
.jump-menu ul a:hover::before,
.jump-menu ul a:active::before {
	border-color: $sn-black;
}

//
//	Services list

.list--services {
	li {
		border-top-color: $sn-gray-medium;
	}
}

//
// Sections

.sec.sec--border,
.sec + .sec {
	border-top-color: $sn-gray-light;
}

//
// Site footer
/*
.site-logo--light {
	display: none;
}
.site-logo--dark {
	display: block;
}
*/

//
// Site header

.site-header {
  a {
    color: $sn-black;
  }
}

//
// Site logo

.site-logo {
	svg {
		color: $sn-black;
	}
}

//
// Typography

// Headings
h1, .is-h1, h2, .is-h2, h3, .is-h3, h4, .is-h4, h5, .is-h5, h6, .is-h6 {
	color: $sn-black;
}
h6, .is-h6 {
	color: $sn-gray-dark;
}
.is-eyebrow {
	color: $sn-gray-dark;
}

// Links
a {
	text-decoration-color: $sn-gray-light;
	&:hover,
	&:active,
	&:focus {
		text-decoration-color: transparent;
	}
}
a.is-incognito {
	text-decoration-color: transparent;
	&:hover,
	&:active,
	&:focus {
		text-decoration-color: $sn-gray-medium;
	}
}

// Block elements
hr {
	border-bottom-color: $sn-gray-light;
}

//
// Utility

// Colors
.sn-gray-medium--light-mode {
	color: $sn-gray-medium
}
.sn-gray--light-mode {
	color: $sn-gray;
}
.sn-gray-light--light-mode {
	color: $sn-gray-light;
}

// Rules
.has-rule {
	border-top-color: $sn-gray-medium;
}
	
} // end .is-light-mode

//
// Mode toggle

.mode-toggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	.mode-toggle__switch {
		margin: 0 5px;
	}
	.mode-toggle__label {
		display: block;
		margin: 0;
		font-size: 1rem;
		color: $sn-gray-medium-2;
		svg {
			color: $sn-gray-medium-2;
		}
	}
	.mode-toggle__label--light {
		transform: translateY(2px);
	}
}

.mode-toggle__switch {
	@include hide-text();
	display: block;
	position: relative;
	border: 2px solid $sn-gray-medium-2;
	border-radius: 10px;
	height: 18px;
	width: 30px;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		height: 10px;
		width: 10px;
		background-color: $sn-gray-medium-2;
		border-radius: 8px;
		transform: translate(2px, -50%); 
	}
}

.mode-toggle__switch,
.mode-toggle__switch::before {
	transition: all .2s;
}

//
// Light mode

.is-light-mode {
	
	.mode-toggle {
		.mode-toggle__label {
			color: $sn-gray-medium;
			svg {
				color: $sn-gray-medium;
			}
		}
	}
	
	.mode-toggle__switch {
		border-color: $sn-gray-medium;
		&::before {
			background-color: $sn-gray-medium;
			transform: translate(14px, -50%);
		}
	}
}