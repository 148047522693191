//
// Site footer
//

.site-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  min-height: $sn-space-1-8-h;
  padding: $viewport-padding-vert 0;
}

//
// The back to top link

.link--top {
  position: relative;
  opacity: 1;
  z-index: 20;
  transition: opacity .8s ease 0s;
  
  @include breakpoint-down(md) {
    display: none;
  }

  @include breakpoint(xl) {
    opacity: 0;
    transition: opacity .4s ease;  
    .is-not-at-top & {
      position: fixed;
      bottom: $viewport-padding-vert;
      left: $viewport-padding-hor;
      opacity: 1;
      transition: opacity .8s ease .1s;
    }
  }
}