//
// Wrapper
//

/*
This is used to get around a bug in Safari that makes it impossible to
switch the background color reliably on the fly. Now the site background
is applied to wrapper so I can do dark/light mode switching
*/

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: $sn-black;
	color: $sn-gray-light;
	transition: background-color .2s;
	z-index: $z-index-bkg;
}