//
// Layout
//

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.site-header,
.site-footer {
  flex-shrink: 0;
}

//
// All major regions

body,
.wrapper,
.site-header,
.site-footer,
.page,
.page__header,
.page__content,
.page__footer {
  margin: 0;
  padding: 0;
}

// body
.wrapper {
  position: relative;
  padding-left: $sn-space-1-16-w;
  padding-right: $sn-space-1-16-w;
}