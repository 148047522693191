//
// Site logo
//

.site-logo {
  position: fixed;
  bottom: 0;
  right: 0;
  width: $sn-space-3-8-w;
  height: $sn-space-3-8-w * 0.8; // proportion of original image is roughly 1:0.8
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: $z-index-top;
  pointer-events: none;
  transition: opacity 800ms ease 100ms,
  	z-index 0ms linear 0ms;
    
  svg {
	  color: $sn-white;
      transition: color .2s;
  }
  
  @include breakpoint(md) {
    width: $sn-space-5-16-w;
    height: $sn-space-5-16-w * 0.8; // proportion of original image is roughly 1:0.8
  }
  @include breakpoint(lg) {
    width: $sn-space-1-4-w;
    height: $sn-space-1-4-w * 0.8; // proportion of original image is roughly 1:0.8
  }  
}

//
// States

.is-not-at-top .site-logo {
	opacity: 0.10;
	z-index: $z-index-logo;
	transition: opacity 300ms ease 0ms,
		z-index 0ms linear 310ms;
}