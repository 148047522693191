//
// Debug
//

// Show current breakpoint

body.debug-breakpoints {
	&::after {
		content: "mobile";
		background: $magenta;
		color: $white;
		display: block;
		padding: 5px 10px;
		position: fixed;
		bottom: 0;
		right: 0;
		font-family: $font-family-base;
		font-weight: $font-weight-normal;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 1;
		letter-spacing: 1px;
		z-index: 999999;

		@include breakpoint(sm) {
			content: "small";
		}

		@include breakpoint(md) {
			content: "medium";
		}

		@include breakpoint(lg) {
			content: "large";
		}

		@include breakpoint(xl) {
			content: "x-large";
		}

		@include breakpoint(xxl) {
			content: "2x-large";
		}
	}
}

body.debug-grid {
	/*
	background-color: $sn-black;
	background: repeating-linear-gradient(
		to bottom,
		transparent,
		transparent $sn-space-1-16-h,
		$sn-gray-dark $sn-space-1-16-h,
		$sn-gray-dark calc(#{$sn-space-1-16-h} + 1px)
	), repeating-linear-gradient(
		to right,
		transparent,
		transparent $sn-space-1-16-w,
		$sn-gray-dark $sn-space-1-16-w,
		$sn-gray-dark calc(#{$sn-space-1-16-w} + 1px)
	), linear-gradient($sn-black 0, $sn-black 100%);
	*/
	&::before {
		content:'';
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-image: url(../../images/sn-fpo-grid.png);
		background-repeat: no-repeat;
		background-position: top left;
		background-size: 100vw 100vh;
		opacity: .5;
		
	}
}

/*
background: repeating-linear-gradient(#e66465, #e66465 20px, #9198e5 20px, #9198e5 25px);
*/