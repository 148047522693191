//
// Site header
//

.site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding: $viewport-padding-vert $viewport-padding-hor;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  
  a {
    color: $sn-white;
  }
}

//
// Jump menu buttons

.jump-menu-btn {
  font-size: rem(12,$font-size-base-number-mobile);
  font-weight: $font-weight-normal;
  line-height: 1.333333333; // 16
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: $sn-black;
  color: $sn-gray-light !important;
  
  @include breakpoint(md) {
    display: none !important;
  }
}

//
// States

// menu closed

.is-jump-menu-closed {
  .jump-menu-open {
    display: block;
  }
  .jump-menu-close {
    display: none;
  }
}

.is-jump-menu-open {
  .jump-menu-open {
    display: none;
  }
  .jump-menu-close {
    display: block;
  }
}