//
// Section
//

/*
.sec
	.sec__header
	.sec__body
*/

.sec {
	
	& + .sec {
		margin-top: $sn-space-1-8-h;
	}
	
	&.sec--border,
	& + .sec {
		border-top: 1px solid $sn-gray;
		padding-top: $grid-gutter-height;
	}
	
	.page__content > &:last-child {
		min-height: $sn-space-7-8-h; // make sure the final section will make a full screen-height element when added to the 1-8-h footer
	}

	
	.sec__header {
		padding-bottom: $sn-space-1-16-h;
		
		> *:last-child {
			margin-bottom: 0;
		}
	}
	
	.sec__body {
		> *:last-child {
			margin-bottom: 0;
		}
	}
	
	@include breakpoint(lg) {
		
		& + .sec {
			margin-top: $sn-space-1-4-h;
		}
		
	}
}

//
// Variations

.sec.sec--flush-top {
	margin-top: 0;
	border-top-width: 0;
	padding-top: 0;
}

.sec.sec--cols {
	
	@include breakpoint-only(lg, xl) {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
		
		.sec__header {
			width: $sn-space-6-16-w;
			max-width: $sn-space-6-16-w;
			flex-basis: $sn-space-6-16-w;
		}
		
		.sec__body {
			width: $sn-space-7-16-w;
			max-width: $sn-space-7-16-w;
			flex-basis: $sn-space-7-16-w;
		}

	}
}