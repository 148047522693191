//
// Jump menu
//

$indicator-size: 12px;
$indicator-border-width: 2px;
$indicator-border-color: $sn-gray;
$indicator-border-radius: $indicator-size * 0.5;
$indicator-dot-size: 4px;
$indicator-dot-border-radius: $indicator-dot-size * 0.5;
$indicator-dot-color: $sn-white;
$indicator-spacing: 10px;

//
// The toggle
//

.jump-menu-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	height: 60px;
	width: 60px;
	border-radius: 50%;
	font-size: rem(12,$font-size-base-number-mobile);
	font-weight: $font-weight-normal;
	line-height: 1;
	letter-spacing: 1px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	background-color: $sn-gray-light;
	color: $sn-black;
	z-index: $z-index-nav + 1;
	transform: translate(25%, -25%);
	
	&:hover,
	&:active {
		text-decoration: none;
		background-color: $sn-white;
	}
	
	.label--closed,
	.label--open {
		@include hide-text;
		width: 60px;
		height: 60px;
		background-repeat: no-repeat;
		background-size: 60px 60px;
		background-position: top left;
	}
	
	.label--closed {
		background-image: url(../../images/icons/sn-icon-menu-black.svg);
	}
	.label--open {
		background-image: url(../../images/icons/sn-icon-close-black.svg);
	}
	
	@include breakpoint(md) {
		display: none;
	}
}

// States

.is-jump-menu-open {
	.jump-menu-toggle .label--closed {
		display: none;
	}
	.jump-menu-toggle .label--open {
		display: block;
	}
}
.is-jump-menu-closed {
	.jump-menu-toggle .label--closed {
		display: block;
	}
	.jump-menu-toggle .label--open {
		display: none;
	}
}

//
// The jump menu
//

/*
nav.jump-menu
	ul
		li
			a#SecWork
				span.jump__label
*/

//
// Base styles

.jump-menu {
	position: fixed;
	z-index: $z-index-nav;

	ul {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		position: relative;
		transition: all .5s ease;
		
		li {
			position: relative;
		}
		
		// The vertical line connecting the nodes
		
		li:not(:first-child)::after {
			content: '';
			position: absolute;
			bottom: 50%;
			left: 50%;
			width: $indicator-border-width;
			background-color: $indicator-border-color;
			z-index: 1;
			transform: translate(#{-$indicator-border-width * 0.5}, 0);
		}
		
		// The links
		
		a {
			position: relative;
			display: block;
			width: 100%;
			text-decoration: none;
	
			// The nodes
			
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			
			// The empty node
			
			&::before {
				height: $indicator-size;
				width: $indicator-size;
				border: $indicator-border-width solid $indicator-border-color;
				border-radius: $indicator-border-radius;
				background-color: $sn-black;
				z-index: 2;
			}
			
			// The node highlight
			
			&::after {
				height: $indicator-dot-size;
				width: $indicator-dot-size;
				border-radius: $indicator-dot-border-radius;
				background-color: $sn-white;
				z-index: 3;
			}
		}
		
		// The label
		
		.jump__label {
			display: block;
			padding: $grid-gutter-height-half $grid-gutter-height;
			position: absolute;
			top: 50%;
			font-weight: $font-weight-normal;
			line-height: 1.333333333; // 16
			letter-spacing: 1px;
			text-transform: uppercase;
			white-space: nowrap;
			color: $sn-gray-light;
		}
	}
}

//
// Mobile vs. desktop differences

.jump-menu {
	
	// Mobile
	
	@include breakpoint-down(md) {
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100vw;
		height: 100vh;
		height: -webkit-fill-available;
		max-height: 100vh;
		max-height: -webkit-fill-available;
		overflow-y: scroll;
		text-align: left;
		background-color: $sn-black-overlay;
		
		ul {
			align-items: flex-start; // align things left
			margin: $viewport-padding-vert $viewport-padding-hor 90px;
			
			// The vertical line connecting the nodes
			
			li:not(:first-child)::after {
				height: $indicator-spacing * 3;
			}
			
			// The links
			
			a {
				height: $indicator-spacing * 3;
			}
			
			// The label
			
			.jump__label {
				left: 50%;
				font-size: rem(12,$font-size-base-number-mobile);
				background-color: transparent;
				transform: translate(#{$indicator-size}, -50%);
			}
		}
	}
	
	// Non-mobile
	
	@include breakpoint(md) {
		top: $viewport-padding-vert; // $sn-space-1-4-h;
		right: 0;
		width: $viewport-padding-hor;
		text-align: right;
		transform: translate(0, #{($indicator-spacing * 2 - $indicator-size) * 0.5}); // optically align with title
		
		ul {
			align-items: stretch;
			
			// The vertical line connecting the nodes
			
			li:not(:first-child)::after {
				height: $indicator-spacing * 2;
			}
			
			// The links
			
			a {
				height: $indicator-spacing * 2;
			}
			
			// The labels
			
			.jump__label {
				right: 50%;
				font-size: rem(15,$font-size-base-number);
				background-color: $sn-black;
				transform: translate(#{-$indicator-size}, -50%);
			}
		}
	}
}

//
// States for the menu itself

// Mobile

@include breakpoint-down(md) {
	
	.is-jump-menu-open {
		.jump-menu {
			display: flex;
			ul {
				opacity: 1;
			}
		}
	}
	.is-jump-menu-closed {
		.jump-menu {
			display: flex;
			height: 0;
			overflow: hidden;
			ul {
				opacity: 0;
			}
		}
	}
	
}

//
// States for the nodes

// Default

.jump-menu ul a::before {
	border-color: $indicator-border-color;
}

// Hover and active

.jump-menu ul a:hover::before,
.jump-menu ul a:active::before {
	border-color: $sn-white;
}

//
// States for node highlights

// Default

.jump-menu ul a::after {
	opacity: 0.001;
}

// Current

.jump-menu ul a.is-current::after {
	opacity: 1;
	transition: opacity .4s ease;
}

//
// States for the node labels

@include breakpoint(md) {
	
	// Default
	
	.jump-menu ul a .jump__label {
		visibility: hidden;
		opacity: 0;
		width: 0;
		overflow: hidden;
	}
	
	// Hover and active
	
	.jump-menu ul a:hover .jump__label,
	.jump-menu ul a:active .jump__label {
		visibility: visible;
		opacity: 1;
		transition: opacity .3s ease;
		width: auto;
	}

}

//
// States for the connecting lines between nodes

// Default

.jump-menu ul li::after {
	opacity: 0.001;
}

// Once node is reached

.jump-menu ul li.is-reached::after {
	opacity: 1;
	transition: opacity .2s ease;
}

//
// Anything with a data-jump-link attribute
//

[data-jump-link]:not(#Top):not(#ProjectDetails):not(#Related) {
	scroll-margin-top: calc($grid-gutter-width - 1px);
}
