//
// Normalize
//

/*
Extremely basic normalize styles.
*/

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */

/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
	max-width: 100%;
	height: auto;
}