//
// Variables
//


//
// Colors
//

$blue: #1d4ed8;
$green: #65a30d;

$primary: $blue;
$secondary: $green;

$white: #FFFFFF;
$black: #111111;

$gray-light: #F4F4F4;
$gray-medium: #707070;
$gray-dark: #444444;

$gray-rule: #C7C9C7;
$gray-border: #B0B0B0;

$text-color: $gray-dark;
$text-black: $black;
$text-dark: $gray-dark;
$text-muted: $gray-medium;
$text-error: #b60000;
$text-success: #3d6308;

$magenta: #e30087;

$link-color: $primary;
$link-color-hover: darken($primary, 10);

// SN colors

$sn-black: #000;
$sn-gray-light: #b0b0b0;
$sn-gray-dark: #2a2a2a;
$sn-gray: #4a4a4a;
$sn-gray-medium-2: #6a6a6a;
$sn-gray-medium: #8a8a8a;
$sn-white: #fff;
$sn-black-overlay: rgba(0,0,0,0.9);
$sn-white-overlay: rgba(255,255,255,0.9);


//
// Typography
//

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;

$font-family-base: 'colfax-web', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-size-base-mobile: 1rem; // 16px
$font-size-base-number-mobile: 16;
$line-height-base-mobile: 1.5; // 27px
$font-size-base: 1rem; // 18px
$font-size-base-number: 18;
$line-height-base: 1.5555556; // 28px

$font-size-larger: 1.5rem; // 24px
$font-size-large: 1.25rem; // 20px
$font-size-small: 0.875rem; // 14px

$heading-size-1: 2.5rem; // 40px
$heading-size-2: 1.6875rem; // 36px
$heading-size-3: 1.125rem; // 18px
$heading-size-4: 1rem; // 16px
$heading-size-5: 1rem; // 16px
$heading-size-6: 1rem; // 16px


//
// Transitions
//

$transition-base: all 0.4s ease;
$transition-btn: all 0.3s ease;
$transition-link: all 0.3s ease;


//
// Grid breakpoints
//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 1024px,
  lg: 1280px,
  xl: 1510px,
  xxl: 1900px 
);


//
// Grid gutters
// 

// $grid-columns: 12;
$grid-gutter-width: math.div(1, 72) * 100vw;
$grid-gutter-height: 10px; // math.div(1, 64) * 100vh; 
$grid-gutter-width-half: $grid-gutter-width * 0.5;
$grid-gutter-width-wide: 40px;
$grid-gutter-height-half: $grid-gutter-height * 0.5;

$grid-gutter-min: 10px;
$grid-column-min-width: 21em;


//
// Baseline grid
//

// Base line spacing
$baseline-grid-unit: .25rem; // 4px

$line-space: $line-height-base * 1rem;
$line-space-1-5x: $line-space * 1.5;
$line-space-2x: $line-space * 2;
$line-space-2-5x: $line-space * 2;
$line-space-3x: $line-space * 3;
$line-space-4x: $line-space * 4;
$line-space-8x: $line-space * 8;
$line-space-half: $line-space * 0.5;

$line-space-mobile: $line-height-base-mobile * 1rem;
$line-space-1-5x-mobile: $line-space-mobile * 1.5;
$line-space-2x-mobile: $line-space-mobile * 2;
$line-space-2-5x-mobile: $line-space-mobile * 2;
$line-space-3x-mobile: $line-space-mobile * 3;
$line-space-4x-mobile: $line-space-mobile * 4;
$line-space-8x-mobile: $line-space-mobile * 8;
$line-space-half-mobile: $line-space-mobile * 0.5;

//
// Vertical space units
//

$sn-space-unit-h:  math.div(1, 16) * 100vh;
$sn-space-1-32-h: $sn-space-unit-h * 0.5;
$sn-space-1-16-h: $sn-space-unit-h;
$sn-space-2-16-h: $sn-space-unit-h * 2;
$sn-space-1-8-h: $sn-space-unit-h * 2;
$sn-space-3-16-h: $sn-space-unit-h * 3;
$sn-space-4-16-h: $sn-space-unit-h * 4;
$sn-space-2-8-h: $sn-space-unit-h * 4;
$sn-space-1-4-h: $sn-space-unit-h * 4;
$sn-space-5-16-h: $sn-space-unit-h * 5;
$sn-space-6-16-h: $sn-space-unit-h * 6;
$sn-space-3-8-h: $sn-space-unit-h * 6;
$sn-space-7-16-h: $sn-space-unit-h * 7;
$sn-space-8-16-h: $sn-space-unit-h * 8;
$sn-space-4-8-h: $sn-space-unit-h * 8;
$sn-space-2-4-h: $sn-space-unit-h * 8;
$sn-space-1-2-h: $sn-space-unit-h * 8;
$sn-space-9-16-h: $sn-space-unit-h * 9;
$sn-space-10-16-h: $sn-space-unit-h * 10;
$sn-space-5-8-h: $sn-space-unit-h * 10;
$sn-space-11-16-h: $sn-space-unit-h * 11;
$sn-space-12-16-h: $sn-space-unit-h * 12;
$sn-space-6-8-h: $sn-space-unit-h * 12;
$sn-space-3-4-h: $sn-space-unit-h * 12;
$sn-space-13-16-h: $sn-space-unit-h * 13;
$sn-space-14-16-h: $sn-space-unit-h * 14;
$sn-space-7-8-h: $sn-space-unit-h * 14;
$sn-space-15-16-h: $sn-space-unit-h * 15;
$sn-space-16-16-h: $sn-space-unit-h * 16;
$sn-space-1-h: $sn-space-unit-h * 16; 

//
// Horizontal space units
//

$sn-space-unit-w:  math.div(1, 16) * 100vw;
$sn-space-1-32-w: $sn-space-unit-w * 0.5;
$sn-space-1-16-w: $sn-space-unit-w;
$sn-space-2-16-w: $sn-space-unit-w * 2;
$sn-space-1-8-w: $sn-space-unit-w * 2;
$sn-space-3-16-w: $sn-space-unit-w * 3;
$sn-space-4-16-w: $sn-space-unit-w * 4;
$sn-space-2-8-w: $sn-space-unit-w * 4;
$sn-space-1-4-w: $sn-space-unit-w * 4;
$sn-space-5-16-w: $sn-space-unit-w * 5;
$sn-space-6-16-w: $sn-space-unit-w * 6;
$sn-space-3-8-w: $sn-space-unit-w * 6;
$sn-space-7-16-w: $sn-space-unit-w * 7;
$sn-space-8-16-w: $sn-space-unit-w * 8;
$sn-space-4-8-w: $sn-space-unit-w * 8;
$sn-space-2-4-w: $sn-space-unit-w * 8;
$sn-space-1-2-w: $sn-space-unit-w * 8;
$sn-space-9-16-w: $sn-space-unit-w * 9;
$sn-space-10-16-w: $sn-space-unit-w * 10;
$sn-space-5-8-w: $sn-space-unit-w * 10;
$sn-space-11-16-w: $sn-space-unit-w * 11;
$sn-space-12-16-w: $sn-space-unit-w * 12;
$sn-space-6-8-w: $sn-space-unit-w * 12;
$sn-space-3-4-w: $sn-space-unit-w * 12;
$sn-space-13-16-w: $sn-space-unit-w * 13;
$sn-space-14-16-w: $sn-space-unit-w * 14;
$sn-space-7-8-w: $sn-space-unit-w * 14;
$sn-space-15-16-w: $sn-space-unit-w * 15;
$sn-space-16-16-w: $sn-space-unit-w * 16;
$sn-space-1-w: $sn-space-unit-w * 16; 

//
// Container padding
//

$container-padding: $grid-gutter-width-half;
$viewport-padding-hor: $sn-space-1-16-w;
$viewport-padding-vert: $sn-space-1-16-h * .25;

// Content

$text-max-width: 840px;
$text-max-width-wide: 950px;
$text-max-width-small: 620px;

//
// Z-indexes
//

$z-index-header: 30;
$z-index-logo: 2;
$z-index-nav: 20;
$z-index-nav-dropdown: 30;
$z-index-nav-overlay: 30;
$z-index-nav-overlay-bg: 10;
$z-index-dropdown: 9;
$z-index-figures: 5;
$z-index-modal: 50;
$z-index-bkg: 1;
$z-index-top: 40;
