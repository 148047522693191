//
// Utility
//

//
// Colors

.sn-gray {
	color: $sn-gray;
}
.sn-gray-medium {
	color: $sn-gray-medium;
}
.sn-gray-light {
	color: $sn-gray-light;
}

//
// Rules

.has-rule {
	border-top: 1px dotted $sn-gray;
	padding-top: $grid-gutter-width;	
}

//
// Visibility

.is-hidden {
  display: none !important;
}

//
// Flexbox order

.order-01 {
	order: 1;
}
.order-01-mobile {
	@include breakpoint-down(md) {
		order: 1;
	}
}
.order-01-md {
	@include breakpoint-only(md, lg) {
		order: 1;
	}
}
.order-01-lg {
	@include breakpoint-only(lg, xl) {
		order: 1;
	}
}
.order-01-xl {
	@include breakpoint(xl) {
		order: 1;
	}
}
.order-02 {
	order: 2;
}
.order-02-mobile {
	@include breakpoint-down(md) {
		order: 2;
	}
}
.order-02-md {
	@include breakpoint-only(md, lg) {
		order: 2;
	}
}
.order-02-lg {
	@include breakpoint-only(lg, xl) {
		order: 2;
	}
}
.order-02-xl {
	@include breakpoint(xl) {
		order: 2;
	}
}