//
// Dividers
//

.divider {
	border: none;
	border-top: 1px solid $sn-gray;
	margin: 0;
	margin-bottom: $grid-gutter-height;	
}

.divider--dotted {
	border-top-style: dotted;
}

.divider--stealth {
	border-top-color: transparent;	
}

//
// Sizes

.divider--1-16 {
	margin: 0;
	padding-top: $sn-space-1-16-h;
}
.divider--1-8 {
	margin: 0;
	padding-top: $sn-space-1-8-h;
}
.divider--1-4 {
	margin: 0;
	padding-top: $sn-space-1-4-h;
}
.divider--2-5 {
	margin: 0;
	padding-top: $sn-space-6-16-h;
}
.divider--1x {
	margin: 0;
	padding-top: $line-space-mobile;
	@include breakpoint(md) {
		padding-top: $line-space;
	}
}
.divider--2x {
	margin: 0;
	padding-top: $line-space-2x-mobile;
	@include breakpoint(md) {
		padding-top: $line-space-2x;
	}
}